import React from "react";
import axios from "axios";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      isSubmittingForm: false,
    };

    this.submitForm = this.submitForm.bind(this);
  }

  submitForm() {
    const form = document.getElementsByName('mc-embedded-subscribe-form')[0]

    form.submit()
    form.reset()
    return false
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.submitForm()
    }
  }

  componentDidMount() { }

  render() {
    return (
      <section className="gh-sign-up">
        <div id="mc_embed_signup">
          <form action="https://gracehouse.us6.list-manage.com/subscribe/post?u=b10ba694861b778d4ce5337e1&amp;id=81ec09d453" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <input onKeyPress={e => {
              this.handleKeyPress(e)
            }} required type="email" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="john@smith.com" />

            <div id="mce-responses" class="clear">
              <div class="response" id="mce-error-response" style={{
                display: 'none'
              }}></div>
              <div class="response" id="mce-success-response" style={{
                display: 'none'
              }}></div>

              <div style={{
                position: 'absolute',
                left: '-5000px'
              }} aria-hidden="true">
                <input type="text" name="b_b10ba694861b778d4ce5337e1_81ec09d453" tabindex="-1" value="" />
              </div>
              <div class="clear">
                <input onClick={() => {
                  this.submitForm()
                }} type="button" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" />
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default SignUp;
